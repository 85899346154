var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "staff-induction-edit-page" },
    [
      _c(
        "el-form",
        {
          key: "staffInductionEditForm",
          ref: "staffInductionEditForm",
          staticClass: "zwx-form edit-form",
          attrs: {
            model: _vm.staffInductionEditForm,
            rules: _vm.staffInductionEditFormRules,
            "label-position": "right"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("base-headline", {
            staticClass: "no-top",
            attrs: { title: "基本信息" }
          }),
          _c("div", { staticStyle: { "border-bottom": "1px solid #D5D9E0" } }, [
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "85px",
                      label: "姓名：",
                      prop: "staffName"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "100px !important" },
                      attrs: {
                        placeholder: "",
                        maxlength: "50",
                        clearable: ""
                      },
                      model: {
                        value: _vm.staffInductionEditForm.staffName,
                        callback: function($$v) {
                          _vm.$set(_vm.staffInductionEditForm, "staffName", $$v)
                        },
                        expression: "staffInductionEditForm.staffName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "82px",
                      label: "证件类型：",
                      prop: "idType"
                    }
                  },
                  [
                    _c("cascader-single", {
                      ref: "idTypeCascader",
                      staticClass: "cascader-single-common",
                      attrs: {
                        simpleCodeList:
                          _vm.staffInductionEditFormExp.idTypeSimpleCodeList,
                        expandTrigger: "hover",
                        codeTypeCode: "1003"
                      },
                      on: { change: _vm.idTypeChange },
                      model: {
                        value: _vm.staffInductionEditForm.idType,
                        callback: function($$v) {
                          _vm.$set(_vm.staffInductionEditForm, "idType", $$v)
                        },
                        expression: "staffInductionEditForm.idType"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "82px",
                      label: "证件号码：",
                      prop: "cardNo"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "200px !important" },
                      attrs: { placeholder: "请输入", clearable: "" },
                      on: {
                        input: function($event) {
                          return _vm.$validate.capitalizationNumbers(
                            _vm.$data["staffInductionEditForm"],
                            "cardNo"
                          )
                        },
                        blur: function($event) {
                          return _vm.initBirthDate($event)
                        }
                      },
                      model: {
                        value: _vm.staffInductionEditForm.cardNo,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.staffInductionEditForm,
                            "cardNo",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "staffInductionEditForm.cardNo"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "54px",
                      label: "性别：",
                      prop: "sex"
                    }
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "zwx-radio-group",
                        staticStyle: { "min-width": "80px !important" },
                        model: {
                          value: _vm.staffInductionEditForm.sex,
                          callback: function($$v) {
                            _vm.$set(_vm.staffInductionEditForm, "sex", $$v)
                          },
                          expression: "staffInductionEditForm.sex"
                        }
                      },
                      [
                        _c(
                          "el-radio",
                          {
                            staticClass: "zwx-radio",
                            attrs: { label: "10010001" }
                          },
                          [_vm._v("男")]
                        ),
                        _c(
                          "el-radio",
                          {
                            staticClass: "zwx-radio",
                            attrs: { label: "10010002" }
                          },
                          [_vm._v("女")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "85px", label: "照片：" } },
                  [
                    _c(
                      "file-upload",
                      {
                        ref: "photoPositive",
                        attrs: {
                          accept: ".png,.jpeg,.jpg,.JPG,.JPEG,.PNG",
                          size: 50 * 1024 * 1024,
                          sizeInfo: "50M"
                        },
                        on: {
                          showFileList: _vm.clickPhotoPositive,
                          success: _vm.clickPhotoPositiveSuccess,
                          error: function($event) {
                            return _vm.clickError()
                          }
                        }
                      },
                      [
                        _vm.$zwxBase.verifyIsBlank(
                          _vm.staffInductionEditForm.photoAnnexList
                        )
                          ? _c(
                              "el-button",
                              {
                                staticClass:
                                  "zwx-button zwx-button-icontext-28",
                                attrs: { icon: "el-icon-upload2" }
                              },
                              [_vm._v("上传")]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.staffInductionEditForm.photoAnnexList.length > 0
                      ? _c(
                          "div",
                          { staticClass: "show-file" },
                          _vm._l(
                            _vm.staffInductionEditForm.photoAnnexList,
                            function(item, index) {
                              return _c(
                                "span",
                                {
                                  key: item.fileName,
                                  on: {
                                    mouseenter: function($event) {
                                      return _vm.photoMouseenter(index)
                                    },
                                    mouseleave: function($event) {
                                      return _vm.photoMouseleave(index)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center",
                                        height: "32px"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                            color: "#194DFF !important",
                                            cursor: "pointer"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.openFilePreview(
                                                _vm.staffInductionEditForm
                                                  .photoAnnexList,
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "colorful chis-icon-colorful-jpg"
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "file-name-hidden",
                                              staticStyle: {
                                                color: "#316CF5!important",
                                                "margin-left": "5px"
                                              }
                                            },
                                            [_vm._v("照片")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "el-upload-list__item check",
                                          style: item.check
                                            ? "margin-left:5px;display:none;"
                                            : "margin-left:5px;cursor:pointer"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-upload-success el-icon-circle-check"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "check",
                                          style: item.check
                                            ? "margin-left:5px;cursor:pointer"
                                            : "margin-left:5px;display:none;"
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-close",
                                            on: {
                                              click: function($event) {
                                                return _vm.delPhotoPositive()
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "85px",
                      label: "出生日期：",
                      prop: "birthDate"
                    }
                  },
                  [
                    _c("el-date-picker", {
                      staticClass: "zwx-date-picker",
                      staticStyle: { width: "200px !important" },
                      attrs: {
                        "popper-class": "zwx-date-picker-popper",
                        "picker-options": _vm.$validate.noOverToday(),
                        type: "date",
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.staffInductionEditForm.birthDate,
                        callback: function($$v) {
                          _vm.$set(_vm.staffInductionEditForm, "birthDate", $$v)
                        },
                        expression: "staffInductionEditForm.birthDate"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "82px", label: "工号：" } },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "200px !important" },
                      attrs: {
                        placeholder: "请输入",
                        maxlength: "20",
                        clearable: ""
                      },
                      on: {
                        input: function($event) {
                          return _vm.$validate.capitalizationNumbers(
                            _vm.$data["staffInductionEditForm"],
                            "jobNumber"
                          )
                        }
                      },
                      model: {
                        value: _vm.staffInductionEditForm.jobNumber,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.staffInductionEditForm,
                            "jobNumber",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "staffInductionEditForm.jobNumber"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "82px",
                      label: "手机号码：",
                      prop: "phone"
                    }
                  },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "200px !important" },
                      attrs: {
                        placeholder: "请输入",
                        maxlength: "20",
                        clearable: ""
                      },
                      model: {
                        value: _vm.staffInductionEditForm.phone,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.staffInductionEditForm,
                            "phone",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "staffInductionEditForm.phone"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "85px",
                      label: "部门车间：",
                      prop: "deptId"
                    }
                  },
                  [
                    _c("cascader-options-single", {
                      ref: "deptCascaderRef",
                      staticClass: "dept-cascader",
                      attrs: {
                        width: "160px",
                        options:
                          _vm.staffInductionEditFormExp.departmentInfoList,
                        dataNameFiled: "workplaceName",
                        dataCodeFiled: "rid",
                        dataUpCodeFiled: "departmentInfoChildList",
                        expandTrigger: "hover",
                        checkStrictly: true
                      },
                      on: { change: _vm.deptChange },
                      model: {
                        value: _vm.staffInductionEditForm.deptId,
                        callback: function($$v) {
                          _vm.$set(_vm.staffInductionEditForm, "deptId", $$v)
                        },
                        expression: "staffInductionEditForm.deptId"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "82px",
                      label: "岗位工种：",
                      prop: "stationId"
                    }
                  },
                  [
                    _c(
                      "el-select",
                      {
                        ref: "stationSelectRef",
                        staticClass: "zwx-select zwx-select-station",
                        attrs: {
                          "popper-class": "zwx-select-popper",
                          placeholder: "请选择"
                        },
                        on: { change: _vm.workTypeChange },
                        model: {
                          value: _vm.staffInductionEditForm.stationId,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.staffInductionEditForm,
                              "stationId",
                              $$v
                            )
                          },
                          expression: "staffInductionEditForm.stationId"
                        }
                      },
                      _vm._l(
                        _vm.staffInductionEditFormExp.deptStationList,
                        function(item) {
                          return _c("el-option", {
                            key: item.rid,
                            attrs: { label: item.jobName, value: item.rid }
                          })
                        }
                      ),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "82px",
                      label: "是否外委：",
                      prop: "outWorker"
                    }
                  },
                  [
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "zwx-radio-group",
                        staticStyle: { "min-width": "80px !important" },
                        model: {
                          value: _vm.staffInductionEditForm.outWorker,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.staffInductionEditForm,
                              "outWorker",
                              $$v
                            )
                          },
                          expression: "staffInductionEditForm.outWorker"
                        }
                      },
                      [
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: true } },
                          [_vm._v("是")]
                        ),
                        _c(
                          "el-radio",
                          { staticClass: "zwx-radio", attrs: { label: false } },
                          [_vm._v("否")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "85px", label: "婚姻状态：" } },
                  [
                    _c("cascader-single", {
                      ref: "ifMarryCascader",
                      staticClass: "cascader-single-common",
                      attrs: {
                        simpleCodeList:
                          _vm.staffInductionEditFormExp.ifMarrySimpleCodeList,
                        expandTrigger: "hover",
                        codeTypeCode: "1007"
                      },
                      model: {
                        value: _vm.staffInductionEditForm.ifMarry,
                        callback: function($$v) {
                          _vm.$set(_vm.staffInductionEditForm, "ifMarry", $$v)
                        },
                        expression: "staffInductionEditForm.ifMarry"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "82px", label: "文化程度：" } },
                  [
                    _c("cascader-single", {
                      ref: "cultureCascader",
                      staticClass: "cascader-single-common",
                      attrs: {
                        simpleCodeList:
                          _vm.staffInductionEditFormExp.cultureSimpleCodeList,
                        expandTrigger: "hover",
                        codeTypeCode: "1005"
                      },
                      model: {
                        value: _vm.staffInductionEditForm.culture,
                        callback: function($$v) {
                          _vm.$set(_vm.staffInductionEditForm, "culture", $$v)
                        },
                        expression: "staffInductionEditForm.culture"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "82px", label: "籍贯：" } },
                  [
                    _c("zwx-select-area", {
                      ref: "nativePlaceArea",
                      staticClass: "zwx-select-area-native-place",
                      attrs: {
                        zoneCode12From:
                          _vm.staffInductionEditFormExp.zoneCode12From,
                        zoneTypeMax: "0",
                        zoneTypeMin: "4",
                        showFullName: true
                      },
                      on: { change: _vm.nativePlaceChange }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "85px", label: "家庭住址：" } },
                  [
                    _c("el-input", {
                      staticClass: "zwx-input",
                      staticStyle: { width: "804px !important" },
                      attrs: {
                        placeholder: "请输入",
                        maxlength: "200",
                        clearable: ""
                      },
                      model: {
                        value: _vm.staffInductionEditForm.homeAddr,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.staffInductionEditForm,
                            "homeAddr",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "staffInductionEditForm.homeAddr"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "85px", label: "嗜好：" } },
                  [
                    _c("el-input", {
                      staticClass: "zwx-textarea",
                      staticStyle: { width: "804px !important" },
                      attrs: {
                        type: "textarea",
                        autosize: { minRows: 4 },
                        maxlength: "2000",
                        resize: "none",
                        placeholder: "请输入",
                        clearable: ""
                      },
                      model: {
                        value: _vm.staffInductionEditForm.hobby,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.staffInductionEditForm,
                            "hobby",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "staffInductionEditForm.hobby"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  { attrs: { "label-width": "85px", label: "家族疾病史：" } },
                  [
                    _c("el-input", {
                      staticClass: "zwx-textarea",
                      staticStyle: { width: "804px !important" },
                      attrs: {
                        type: "textarea",
                        autosize: { minRows: 4 },
                        maxlength: "2000",
                        resize: "none",
                        placeholder: "请输入",
                        clearable: ""
                      },
                      model: {
                        value: _vm.staffInductionEditForm.familyIllness,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.staffInductionEditForm,
                            "familyIllness",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "staffInductionEditForm.familyIllness"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c("div", { staticStyle: { "border-bottom": "1px solid #D5D9E0" } }, [
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "137px",
                      label: "初次参加工作时间："
                    }
                  },
                  [
                    _c("el-date-picker", {
                      staticClass: "zwx-date-picker",
                      staticStyle: { width: "200px !important" },
                      attrs: {
                        "popper-class": "zwx-date-picker-popper",
                        type: "date",
                        "picker-options": _vm.$validate.noOverToday(
                          _vm.$data["staffInductionEditForm"],
                          "firstWorkDate"
                        ),
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.staffInductionEditForm.firstWorkDate,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.staffInductionEditForm,
                            "firstWorkDate",
                            $$v
                          )
                        },
                        expression: "staffInductionEditForm.firstWorkDate"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "168px",
                      label: "上岗前职业健康检查日期：",
                      prop: "prePeDate"
                    }
                  },
                  [
                    _c("el-date-picker", {
                      staticClass: "zwx-date-picker",
                      staticStyle: { width: "200px !important" },
                      attrs: {
                        "popper-class": "zwx-date-picker-popper",
                        type: "date",
                        "picker-options": _vm.$validate.noOverToday(
                          _vm.$data["staffInductionEditForm"],
                          "prePeDate"
                        ),
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.staffInductionEditForm.prePeDate,
                        callback: function($$v) {
                          _vm.$set(_vm.staffInductionEditForm, "prePeDate", $$v)
                        },
                        expression: "staffInductionEditForm.prePeDate"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "edit-row" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "137px",
                      label: "到本单位工作时间：",
                      prop: "hiredate"
                    }
                  },
                  [
                    _c("el-date-picker", {
                      staticClass: "zwx-date-picker",
                      staticStyle: { width: "200px !important" },
                      attrs: {
                        "popper-class": "zwx-date-picker-popper",
                        type: "date",
                        "picker-options": _vm.$validate.noOverToday(
                          _vm.$data["staffInductionEditForm"],
                          "hiredate"
                        ),
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.staffInductionEditForm.hiredate,
                        callback: function($$v) {
                          _vm.$set(_vm.staffInductionEditForm, "hiredate", $$v)
                        },
                        expression: "staffInductionEditForm.hiredate"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      "label-width": "168px",
                      label: "上岗前职业卫生培训日期：",
                      prop: "preTrainingDate"
                    }
                  },
                  [
                    _c("el-date-picker", {
                      staticClass: "zwx-date-picker",
                      staticStyle: { width: "200px !important" },
                      attrs: {
                        "popper-class": "zwx-date-picker-popper",
                        type: "date",
                        "picker-options": _vm.$validate.noOverToday(
                          _vm.$data["staffInductionEditForm"],
                          "preTrainingDate"
                        ),
                        placeholder: "请选择"
                      },
                      model: {
                        value: _vm.staffInductionEditForm.preTrainingDate,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.staffInductionEditForm,
                            "preTrainingDate",
                            $$v
                          )
                        },
                        expression: "staffInductionEditForm.preTrainingDate"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "base-headline",
            { staticClass: "no-top no-bottom", attrs: { title: "职业史" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: { icon: "el-icon-plus" },
                  on: { click: _vm.addStaffOccHistory }
                },
                [_vm._v("添加")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              key: "tdStaffInfoTable",
              staticClass: "zwx-table",
              staticStyle: { width: "100%" },
              attrs: {
                "row-key": "rid",
                data: _vm.staffInductionEditForm.tdStaffOccHistoryList,
                stripe: "",
                border: "",
                "tooltip-effect": "light"
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "startDate",
                  label: "工作开始日期",
                  width: "120",
                  "header-align": "center",
                  align: "center",
                  formatter: _vm.dateFormatter
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "endDate",
                  label: "工作结束日期",
                  width: "120",
                  "header-align": "center",
                  align: "center",
                  formatter: _vm.dateFormatter
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "employerName",
                  label: "用人单位",
                  width: "200",
                  "header-align": "center",
                  align: "left",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "department",
                  label: "部门 (车间)",
                  width: "200",
                  "header-align": "center",
                  align: "left",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "workType",
                  label: "岗位 (工种)",
                  width: "200",
                  "header-align": "center",
                  align: "left",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "exposureHazards",
                  label: "接触危害因素",
                  "min-width": "200",
                  "header-align": "center",
                  align: "left",
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "center",
                  align: "left",
                  fixed: "right",
                  "min-width": "90"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass: "zwx-button zwx-button-text-26",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.editStaffOccHistory(
                                  scope.row,
                                  scope.$index
                                )
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass:
                              "zwx-button zwx-button-text-26 zwx-button-danger",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.deleteStaffOccHistory(scope.$index)
                              }
                            }
                          },
                          [_vm._v("删除")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "base-headline",
            { staticClass: "no-top no-bottom", attrs: { title: "既往病史" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "zwx-button zwx-button-icontext-28",
                  attrs: { icon: "el-icon-plus" },
                  on: { click: _vm.addStaffAnamnesis }
                },
                [_vm._v("添加")]
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              key: "staffAnamnesisListTable",
              staticClass: "zwx-table",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.staffInductionEditForm.staffAnamnesisList,
                stripe: "",
                border: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "diseaseName",
                  label: "疾病或手术史",
                  width: "200",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "staffAnamnesisList." +
                                scope.$index +
                                ".diseaseName",
                              rules: _vm.staffInductionEditFormRules.diseaseName
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "zwx-input",
                              attrs: {
                                placeholder: "请输入",
                                maxlength: "100",
                                clearable: ""
                              },
                              model: {
                                value: scope.row.diseaseName,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "diseaseName", $$v)
                                },
                                expression: "scope.row.diseaseName"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "diagnoseDate",
                  label: "就诊日期",
                  width: "180",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "staffAnamnesisList." +
                                scope.$index +
                                ".diagnoseDate",
                              rules:
                                _vm.staffInductionEditFormRules.diagnoseDate
                            }
                          },
                          [
                            _c("el-date-picker", {
                              staticClass: "zwx-date-picker",
                              attrs: {
                                "popper-class": "zwx-date-picker-popper",
                                type: "date",
                                "picker-options": _vm.$validate.noOverToday(
                                  _vm.$data["staffInductionEditForm"][
                                    "staffAnamnesisList"
                                  ][scope.$index],
                                  "diagnosisDate"
                                ),
                                placeholder: "请选择"
                              },
                              model: {
                                value: scope.row.diagnoseDate,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "diagnoseDate", $$v)
                                },
                                expression: "scope.row.diagnoseDate"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "diagnoseUnit",
                  label: "就诊单位",
                  width: "260",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "staffAnamnesisList." +
                                scope.$index +
                                ".diagnoseUnit",
                              rules:
                                _vm.staffInductionEditFormRules.diagnoseUnit
                            }
                          },
                          [
                            _c("el-input", {
                              staticClass: "zwx-input",
                              attrs: {
                                placeholder: "请输入",
                                maxlength: "100",
                                clearable: ""
                              },
                              model: {
                                value: scope.row.diagnoseUnit,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "diagnoseUnit", $$v)
                                },
                                expression: "scope.row.diagnoseUnit"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "treatment",
                  label: "治疗结果",
                  width: "260",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          [
                            _c("el-input", {
                              staticClass: "zwx-input",
                              attrs: {
                                placeholder: "请输入",
                                maxlength: "100",
                                clearable: ""
                              },
                              model: {
                                value: scope.row.treatment,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "treatment", $$v)
                                },
                                expression: "scope.row.treatment"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "prognosis",
                  label: "转归",
                  width: "180",
                  "header-align": "center",
                  align: "center"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          [
                            _c("el-input", {
                              staticClass: "zwx-input",
                              attrs: {
                                placeholder: "请输入",
                                maxlength: "50",
                                clearable: ""
                              },
                              model: {
                                value: scope.row.prognosis,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "prognosis", $$v)
                                },
                                expression: "scope.row.prognosis"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  "header-align": "left",
                  align: "left",
                  "min-width": "80"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticClass:
                              "zwx-button zwx-button-text-26 zwx-button-danger",
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.delStaffAnamnesis(scope.$index)
                              }
                            }
                          },
                          [_vm._v(" 删除 ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "fixed-footer",
        [
          _c(
            "el-button",
            {
              staticClass: "zwx-button zwx-button-32",
              attrs: { type: "primary", disabled: _vm.saveButtonDisabled },
              on: { click: _vm.submitStaffInfo }
            },
            [_vm._v("保存")]
          )
        ],
        1
      ),
      _c(
        "base-dialog",
        {
          ref: "staffOccHistoryDialogRef",
          staticClass: "staff-occ-history-dialog",
          attrs: {
            title: _vm.staffOccHistoryDialog.title,
            pagination: false,
            "if-show-close": true
          },
          on: {
            determine: function($event) {
              return _vm.confirmStaffOccHistoryDialog()
            },
            cancel: _vm.cancelStaffOccHistoryDialog,
            close: _vm.closeStaffOccHistoryDialog
          }
        },
        [
          _c(
            "el-form",
            {
              key: "staffOccHistoryEditForm",
              ref: "staffOccHistoryEditFormRef",
              staticClass: "zwx-form edit-form",
              attrs: {
                model: _vm.staffOccHistoryDialog.staffOccHistoryEditForm,
                "label-position": "right",
                rules: _vm.staffOccHistoryDialog.staffOccHistoryEditFormRules
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "edit-row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "110px",
                          label: "用人单位：",
                          prop: "employerName"
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "438px !important" },
                          attrs: {
                            placeholder: "请输入",
                            maxlength: "100",
                            clearable: ""
                          },
                          model: {
                            value:
                              _vm.staffOccHistoryDialog.staffOccHistoryEditForm
                                .employerName,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.staffOccHistoryDialog
                                  .staffOccHistoryEditForm,
                                "employerName",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "staffOccHistoryDialog.staffOccHistoryEditForm.employerName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "edit-row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "110px",
                          label: "部门（车间）：",
                          prop: "department"
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "438px !important" },
                          attrs: {
                            placeholder: "",
                            maxlength: "100",
                            clearable: ""
                          },
                          model: {
                            value:
                              _vm.staffOccHistoryDialog.staffOccHistoryEditForm
                                .department,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.staffOccHistoryDialog
                                  .staffOccHistoryEditForm,
                                "department",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "staffOccHistoryDialog.staffOccHistoryEditForm.department"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "edit-row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "110px",
                          label: "岗位（工种）：",
                          prop: "workType"
                        }
                      },
                      [
                        _c("el-input", {
                          staticClass: "zwx-input",
                          staticStyle: { width: "438px !important" },
                          attrs: {
                            placeholder: "",
                            maxlength: "50",
                            clearable: ""
                          },
                          model: {
                            value:
                              _vm.staffOccHistoryDialog.staffOccHistoryEditForm
                                .workType,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.staffOccHistoryDialog
                                  .staffOccHistoryEditForm,
                                "workType",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression:
                              "staffOccHistoryDialog.staffOccHistoryEditForm.workType"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "edit-row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "110px",
                          label: "工作开始时间：",
                          prop: "startDate"
                        }
                      },
                      [
                        _c("el-date-picker", {
                          staticClass: "zwx-date-picker",
                          staticStyle: { width: "160px !important" },
                          attrs: {
                            "popper-class": "zwx-date-picker-popper",
                            type: "date",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            "picker-options": _vm.$validate.noBigDateNoOverToday(
                              _vm.$data["staffOccHistoryDialog"][
                                "staffOccHistoryEditForm"
                              ],
                              "endDate"
                            ),
                            placeholder: "开始时间"
                          },
                          model: {
                            value:
                              _vm.staffOccHistoryDialog.staffOccHistoryEditForm
                                .startDate,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.staffOccHistoryDialog
                                  .staffOccHistoryEditForm,
                                "startDate",
                                $$v
                              )
                            },
                            expression:
                              "staffOccHistoryDialog.staffOccHistoryEditForm.startDate"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { "margin-right": "0 !important" },
                        attrs: {
                          "label-width": "98px",
                          label: "工作结束时间：",
                          prop: "endDate"
                        }
                      },
                      [
                        _c("el-date-picker", {
                          staticClass: "zwx-date-picker",
                          staticStyle: { width: "160px !important" },
                          attrs: {
                            "popper-class": "zwx-date-picker-popper",
                            type: "date",
                            format: "yyyy-MM-dd",
                            "value-format": "yyyy-MM-dd",
                            "picker-options": _vm.$validate.noSmallDateNoOverToday(
                              _vm.$data["staffOccHistoryDialog"][
                                "staffOccHistoryEditForm"
                              ],
                              "startDate"
                            ),
                            placeholder: "结束时间"
                          },
                          model: {
                            value:
                              _vm.staffOccHistoryDialog.staffOccHistoryEditForm
                                .endDate,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.staffOccHistoryDialog
                                  .staffOccHistoryEditForm,
                                "endDate",
                                $$v
                              )
                            },
                            expression:
                              "staffOccHistoryDialog.staffOccHistoryEditForm.endDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "edit-row" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "110px",
                          label: "接触危害因素："
                        }
                      },
                      [
                        _c("hazard-factor-multiple-edit", {
                          ref:
                            "hazardFactorMultipleEditRef" +
                            _vm.staffOccHistoryDialog.staffOccHistoryEditForm
                              .uuid,
                          staticClass: "hazard-factor-multiple-components",
                          attrs: {
                            innerWidth: 438,
                            options: _vm.staffOccHistoryDialog.hazardShowList,
                            dataPyxNameFiled: "hazardsName",
                            dataNameFiled: "hazardsName",
                            showNameFiled: "hazardName",
                            dataCodeFiled: "codeNo",
                            dataUpCodeFiled: "upcodeNo"
                          },
                          on: { change: _vm.hazardsChange }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }