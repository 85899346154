<template>
  <div v-if="ifShow" class="wx-exam-record-page">
    <exam-record-detail ref="examRecordDetailRef"></exam-record-detail>
  </div>
</template>

<script>
export default {
  name: 'ExamResultDetails',
  props: {},
  data() {
    return {
      api: this.$store.state.api,
      uuid: this.$route.params.uuid,
      ifShow: false,
    }
  },
  computed: {},
  watch: {},
  created() { },
  mounted() {
    if (!this.$zwxBase.verifyIsBlank(this.uuid)) {
      this.getExamRecordDetail()
    }
  },
  methods: {
    // 获取答卷信息数据
    getExamRecordDetail() {
      let data = {
        uuid: this.uuid,
      }
      this.$emit('loading', true)
      this.$system.post(
        this.api + '/training/platform/getExamRecordDetail-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.ifShow = true
            setTimeout(() => {
              this.$refs.examRecordDetailRef.ExamRecordDetailInit(data)
            }, 100)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
  },
}
</script>

<style scoped lang="less">
.wx-exam-record-page {
  /deep/.zwx-exam-record-main {
    height: calc(100vh - 44px) !important;
  }
}
</style>
