var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ifShow
    ? _c(
        "div",
        { staticClass: "wx-exam-record-page" },
        [_c("exam-record-detail", { ref: "examRecordDetailRef" })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }