<template>
  <div class="staff-induction-edit-page">
    <el-form class="zwx-form edit-form" key="staffInductionEditForm" :model="staffInductionEditForm" :rules="staffInductionEditFormRules" ref="staffInductionEditForm" label-position="right" @submit.native.prevent>
      <base-headline title="基本信息" class="no-top"></base-headline>
      <div style="border-bottom: 1px solid #D5D9E0">
        <div class="edit-row">
          <el-form-item label-width="85px" label="姓名：" prop="staffName">
            <el-input style="width:100px !important" class="zwx-input" placeholder="" v-model="staffInductionEditForm.staffName" maxlength="50" clearable />
          </el-form-item>
          <el-form-item label-width="82px" label="证件类型：" prop="idType">
            <cascader-single class="cascader-single-common" ref="idTypeCascader" v-model="staffInductionEditForm.idType" :simpleCodeList="staffInductionEditFormExp.idTypeSimpleCodeList" expandTrigger="hover" codeTypeCode="1003" @change="idTypeChange"></cascader-single>
          </el-form-item>
          <el-form-item label-width="82px" label="证件号码：" prop="cardNo">
            <el-input class="zwx-input " placeholder="请输入" style="width: 200px !important;" @input="$validate.capitalizationNumbers($data['staffInductionEditForm'], 'cardNo')" v-model.trim="staffInductionEditForm.cardNo" @blur="initBirthDate($event)" clearable />
          </el-form-item>
          <el-form-item label-width="54px" label="性别：" prop="sex">
            <el-radio-group class="zwx-radio-group" style="min-width: 80px !important;" v-model="staffInductionEditForm.sex">
              <el-radio class="zwx-radio" label="10010001">男</el-radio>
              <el-radio class="zwx-radio" label="10010002">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="85px" label="照片：">
            <file-upload ref="photoPositive" @showFileList="clickPhotoPositive" @success="clickPhotoPositiveSuccess" @error="clickError()" accept=".png,.jpeg,.jpg,.JPG,.JPEG,.PNG" :size="50 * 1024 * 1024" sizeInfo="50M">
              <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(staffInductionEditForm.photoAnnexList)">上传</el-button>
            </file-upload>
            <div class="show-file" v-if="staffInductionEditForm.photoAnnexList.length > 0">
              <span v-for="(item, index) in staffInductionEditForm.photoAnnexList" :key="item.fileName" @mouseenter="photoMouseenter(index)" @mouseleave="photoMouseleave(index)">
                <div style="display: flex;align-items:center;height: 32px">
                  <div style="display: flex;align-items:center;color:#194DFF !important;cursor:pointer;" @click="openFilePreview(staffInductionEditForm.photoAnnexList, index)">
                    <i class="colorful chis-icon-colorful-jpg"></i>
                    <div class="file-name-hidden" style="color:#316CF5!important;margin-left:5px;">照片</div>
                  </div>
                  <div class="el-upload-list__item check" :style="item.check ? 'margin-left:5px;display:none;' : 'margin-left:5px;cursor:pointer'">
                    <i class="el-icon-upload-success el-icon-circle-check"></i>
                  </div>
                  <div class="check" :style="item.check ? 'margin-left:5px;cursor:pointer' : 'margin-left:5px;display:none;'">
                    <i class="el-icon-close" @click="delPhotoPositive()"></i>
                  </div>
                </div>
              </span>
            </div>
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="85px" label="出生日期：" prop="birthDate">
            <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;" :picker-options="$validate.noOverToday()" v-model="staffInductionEditForm.birthDate" type="date" placeholder="请选择" />
          </el-form-item>
          <el-form-item label-width="82px" label="工号：">
            <el-input class="zwx-input " placeholder="请输入" style="width: 200px !important;" @input="$validate.capitalizationNumbers($data['staffInductionEditForm'], 'jobNumber')" v-model.trim="staffInductionEditForm.jobNumber" maxlength="20" clearable />
          </el-form-item>
          <el-form-item label-width="82px" label="手机号码：" prop="phone">
            <el-input class="zwx-input " placeholder="请输入" style="width: 200px !important;" maxlength="20" v-model.trim="staffInductionEditForm.phone" clearable />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="85px" label="部门车间：" prop="deptId">
            <cascader-options-single class="dept-cascader" ref="deptCascaderRef" width="160px" :options="staffInductionEditFormExp.departmentInfoList" v-model="staffInductionEditForm.deptId" dataNameFiled="workplaceName" dataCodeFiled="rid" dataUpCodeFiled="departmentInfoChildList" expandTrigger="hover" :checkStrictly="true" @change="deptChange" />
          </el-form-item>
          <el-form-item label-width="82px" label="岗位工种：" prop="stationId">
            <el-select class="zwx-select zwx-select-station" ref="stationSelectRef" popper-class="zwx-select-popper" @change="workTypeChange" v-model="staffInductionEditForm.stationId" placeholder="请选择">
              <el-option v-for="item in staffInductionEditFormExp.deptStationList" :key="item.rid" :label="item.jobName" :value="item.rid"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label-width="82px" label="是否外委：" prop="outWorker">
            <el-radio-group class="zwx-radio-group" v-model="staffInductionEditForm.outWorker" style="min-width: 80px !important;">
              <el-radio class="zwx-radio" :label="true">是</el-radio>
              <el-radio class="zwx-radio" :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="85px" label="婚姻状态：">
            <cascader-single class="cascader-single-common" ref="ifMarryCascader" v-model="staffInductionEditForm.ifMarry" :simpleCodeList="staffInductionEditFormExp.ifMarrySimpleCodeList" expandTrigger="hover" codeTypeCode="1007" />
          </el-form-item>
          <el-form-item label-width="82px" label="文化程度：">
            <cascader-single class="cascader-single-common" ref="cultureCascader" v-model="staffInductionEditForm.culture" :simpleCodeList="staffInductionEditFormExp.cultureSimpleCodeList" expandTrigger="hover" codeTypeCode="1005" />
          </el-form-item>
          <el-form-item label-width="82px" label="籍贯：">
            <zwx-select-area class="zwx-select-area-native-place" ref="nativePlaceArea" :zoneCode12From="staffInductionEditFormExp.zoneCode12From" zoneTypeMax="0" zoneTypeMin="4" :showFullName="true" @change="nativePlaceChange" />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="85px" label="家庭住址：">
            <el-input class="zwx-input " placeholder="请输入" style="width: 804px !important;" v-model.trim="staffInductionEditForm.homeAddr" maxlength="200" clearable />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="85px" label="嗜好：">
            <el-input class="zwx-textarea" type="textarea" v-model.trim="staffInductionEditForm.hobby" style="width: 804px !important;" :autosize="{ minRows: 4 }" maxlength="2000" resize="none" placeholder="请输入" clearable />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="85px" label="家族疾病史：">
            <el-input class="zwx-textarea" type="textarea" v-model.trim="staffInductionEditForm.familyIllness" style="width: 804px !important;" :autosize="{ minRows: 4}" maxlength="2000" resize="none" placeholder="请输入" clearable />
          </el-form-item>
        </div>
      </div>
      <div style="border-bottom: 1px solid #D5D9E0">
        <div class="edit-row">
          <el-form-item label-width="137px" label="初次参加工作时间：">
            <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;" v-model="staffInductionEditForm.firstWorkDate" type="date" :picker-options="$validate.noOverToday($data['staffInductionEditForm'], 'firstWorkDate')" placeholder="请选择" />
          </el-form-item>
          <el-form-item label-width="168px" label="上岗前职业健康检查日期：" prop="prePeDate">
            <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;" v-model="staffInductionEditForm.prePeDate" type="date" :picker-options="$validate.noOverToday($data['staffInductionEditForm'], 'prePeDate')" placeholder="请选择" />
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="137px" label="到本单位工作时间：" prop="hiredate">
            <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;" v-model="staffInductionEditForm.hiredate" type="date" :picker-options="$validate.noOverToday($data['staffInductionEditForm'], 'hiredate')" placeholder="请选择" />
          </el-form-item>
          <el-form-item label-width="168px" label="上岗前职业卫生培训日期：" prop="preTrainingDate">
            <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;" v-model="staffInductionEditForm.preTrainingDate" type="date" :picker-options="$validate.noOverToday($data['staffInductionEditForm'], 'preTrainingDate')" placeholder="请选择" />
          </el-form-item>
        </div>
      </div>
      <base-headline title="职业史" class="no-top no-bottom">
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addStaffOccHistory">添加</el-button>
      </base-headline>
      <el-table class="zwx-table" style="width: 100%" row-key="rid" key="tdStaffInfoTable" :data="staffInductionEditForm.tdStaffOccHistoryList" stripe border tooltip-effect="light">
        <el-table-column prop="startDate" label="工作开始日期" width="120" header-align="center" align="center" :formatter="dateFormatter"></el-table-column>
        <el-table-column prop="endDate" label="工作结束日期" width="120" header-align="center" align="center" :formatter="dateFormatter"></el-table-column>
        <el-table-column prop="employerName" label="用人单位" width="200" header-align="center" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="department" label="部门 (车间)" width="200" header-align="center" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="workType" label="岗位 (工种)" width="200" header-align="center" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column prop="exposureHazards" label="接触危害因素" min-width="200" header-align="center" align="left" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" header-align="center" align="left" fixed="right" min-width="90">
          <template slot-scope="scope">
            <el-button class="zwx-button zwx-button-text-26" type="text" @click="editStaffOccHistory(scope.row, scope.$index)">编辑</el-button>
            <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" type="text" @click="deleteStaffOccHistory(scope.$index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <base-headline title="既往病史" class="no-top no-bottom">
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addStaffAnamnesis">添加</el-button>
      </base-headline>
      <el-table class="zwx-table" style="width: 100%" key="staffAnamnesisListTable" :data="staffInductionEditForm.staffAnamnesisList" stripe border>
        <el-table-column prop="diseaseName" label="疾病或手术史" width="200" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'staffAnamnesisList.' + scope.$index + '.diseaseName'" :rules="staffInductionEditFormRules.diseaseName">
              <el-input class="zwx-input" v-model="scope.row.diseaseName" placeholder="请输入" maxlength="100" clearable />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="diagnoseDate" label="就诊日期" width="180" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'staffAnamnesisList.' + scope.$index + '.diagnoseDate'" :rules="staffInductionEditFormRules.diagnoseDate">
              <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" v-model="scope.row.diagnoseDate" type="date" :picker-options="$validate.noOverToday($data['staffInductionEditForm']['staffAnamnesisList'][scope.$index], 'diagnosisDate')" placeholder="请选择" />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="diagnoseUnit" label="就诊单位" width="260" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'staffAnamnesisList.' + scope.$index + '.diagnoseUnit'" :rules="staffInductionEditFormRules.diagnoseUnit">
              <el-input class="zwx-input" v-model="scope.row.diagnoseUnit" placeholder="请输入" maxlength="100" clearable />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="treatment" label="治疗结果" width="260" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item>
              <el-input class="zwx-input" v-model="scope.row.treatment" placeholder="请输入" maxlength="100" clearable />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="prognosis" label="转归" width="180" header-align="center" align="center">
          <template slot-scope="scope">
            <el-form-item>
              <el-input class="zwx-input" v-model="scope.row.prognosis" placeholder="请输入" maxlength="50" clearable />
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" header-align="left" align="left" min-width="80">
          <template slot-scope="scope">
            <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" type="text" @click="delStaffAnamnesis(scope.$index)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <fixed-footer>
      <el-button class="zwx-button zwx-button-32" type="primary" @click="submitStaffInfo" :disabled="saveButtonDisabled">保存</el-button>
    </fixed-footer>
    <!--职业史弹框-->
    <base-dialog class="staff-occ-history-dialog" ref="staffOccHistoryDialogRef" :title="staffOccHistoryDialog.title" @determine="confirmStaffOccHistoryDialog()" @cancel="cancelStaffOccHistoryDialog" @close="closeStaffOccHistoryDialog" :pagination="false" :if-show-close="true">
      <el-form class="zwx-form edit-form" key="staffOccHistoryEditForm" ref="staffOccHistoryEditFormRef" :model="staffOccHistoryDialog.staffOccHistoryEditForm" label-position="right" :rules="staffOccHistoryDialog.staffOccHistoryEditFormRules" @submit.native.prevent>
        <div>
          <div class="edit-row">
            <el-form-item label-width="110px" label="用人单位：" prop="employerName">
              <el-input class="zwx-input " style="width: 438px !important;" v-model.trim="staffOccHistoryDialog.staffOccHistoryEditForm.employerName" placeholder="请输入" maxlength="100" clearable />
            </el-form-item>
          </div>
          <div class="edit-row">
            <el-form-item label-width="110px" label="部门（车间）：" prop="department">
              <el-input class="zwx-input" style="width:438px !important" v-model.trim="staffOccHistoryDialog.staffOccHistoryEditForm.department" placeholder="" maxlength="100" clearable />
            </el-form-item>
          </div>
          <div class="edit-row">
            <el-form-item label-width="110px" label="岗位（工种）：" prop="workType">
              <el-input class="zwx-input" style="width:438px !important" v-model.trim="staffOccHistoryDialog.staffOccHistoryEditForm.workType" placeholder="" maxlength="50" clearable />
            </el-form-item>
          </div>
          <div class="edit-row">
            <el-form-item label-width="110px" label="工作开始时间：" prop="startDate">
              <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 160px !important;" v-model="staffOccHistoryDialog.staffOccHistoryEditForm.startDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noBigDateNoOverToday($data['staffOccHistoryDialog']['staffOccHistoryEditForm'], 'endDate')" placeholder="开始时间" />
            </el-form-item>
            <el-form-item label-width="98px" label="工作结束时间：" prop="endDate" style="margin-right: 0 !important;">
              <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" style="width: 160px !important;" v-model="staffOccHistoryDialog.staffOccHistoryEditForm.endDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noSmallDateNoOverToday($data['staffOccHistoryDialog']['staffOccHistoryEditForm'], 'startDate')" placeholder="结束时间" />
            </el-form-item>
          </div>
          <div class="edit-row">
            <el-form-item label-width="110px" label="接触危害因素：">
              <hazard-factor-multiple-edit class="hazard-factor-multiple-components" :ref="'hazardFactorMultipleEditRef' + staffOccHistoryDialog.staffOccHistoryEditForm.uuid" :innerWidth="438" :options="staffOccHistoryDialog.hazardShowList" dataPyxNameFiled="hazardsName" dataNameFiled="hazardsName" showNameFiled="hazardName" dataCodeFiled="codeNo" dataUpCodeFiled="upcodeNo" @change="hazardsChange">

              </hazard-factor-multiple-edit>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </base-dialog>
  </div>
</template>

<script>
export default {
  name: 'StaffInductionEditPage',
  data() {
    return {
      api: this.$store.state.api,
      type: this.$route.params.type,
      staffInductionEditForm: {
        rid: '',
        unitId: '',
        employerUuid: '',
        deptId: '',
        stationId: '',
        type: '',
        staffName: '',
        pinyinCode: '',
        jobNumber: '',
        birthDate: '',
        sex: '',
        idType: '',
        cardNo: '',
        phone: '',
        outWorker: '',
        nativePlace: '',
        ifMarry: '',
        culture: '',
        homeAddr: '',
        hobby: '',
        familyIllness: '',
        firstWorkDate: null,
        hiredate: null,
        prePeDate: null,
        preTrainingDate: null,
        type: null,
        dutyStatus: null,
        tdStaffOccHistoryList: [],
        staffAnamnesisList: [],
        personnelPhoto: '',
        photoAnnexList: [], // 照片附件集合
      },
      staffInductionEditFormExp: {
        folder: '/staff_management',
        zoneCode12From: '', // 地区
        idTypeSimpleCodeList: [], // 证件类型下拉
        ifMarrySimpleCodeList: [], // 婚姻状况下拉
        cultureSimpleCodeList: [], // 文化程度下拉
        departmentInfoList: [], // 部门下拉
        deptStationList: [], // 部门关联岗位下拉
        hazardShowList: [], // 危害因素数据源
        staffInductionEditFormJson: '',
      },
      staffInductionEditFormRules: {
        staffName: [{ required: true, message: '请输入姓名', trigger: ['blur'] }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        birthDate: [{ required: true, message: '请选择出生日期', trigger: 'change' }],
        idType: [{ required: true, message: '请选择证件类型', trigger: 'change' }],
        cardNo: [
          { required: true, message: '请输入证件号码', trigger: 'change' },
          { validator: this.validatorIdCard, trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: this.validatorPhone, trigger: 'blur' },
        ],
        hiredate: [{ required: true, message: '请选择到本单位工作时间', trigger: 'change' }],
        // prePeDate: [{ validator: this.validatorPrePeDate, trigger: ['change'] }],
        // preTrainingDate: [{ validator: this.validatorPreTrainingDate, trigger: ['change'] }],
        deptId: [{ required: true, message: '请选择部门(车间)', trigger: 'change' }],
        stationId: [{ required: true, message: '请选择岗位(工种)', trigger: 'change' }],
        outWorker: [{ required: true, message: '请选择是否外委', trigger: 'change' }],
        diseaseName: [{ validator: this.validatorNotEmpty, trigger: ['blur'] }],
        diagnoseDate: [{ validator: this.validatorNotEmpty, trigger: ['change'] }],
        diagnoseUnit: [{ validator: this.validatorNotEmpty, trigger: ['blur'] }],
        treatResult: [{ validator: this.validatorNotEmpty, trigger: ['blur'] }],
      },
      staffOccHistoryDialog: {
        type: '',
        title: '',
        rowIndex: '',
        staffOccHistoryEditForm: {
          uuid: '',
          employerName: '',
          department: '',
          workType: '',
          startDate: '',
          endDate: '',
          exposureHazards: '',
          occupationalHistoryHazardExpList: [],
          hazardsCodeList: [],
        },
        staffOccHistoryEditFormRules: {
          employerName: [{ required: true, message: '请输入单位名称', trigger: 'blur' }],
          startDate: [{ required: true, message: '请选择开始日期', trigger: 'change' }],
        },
        hazardShowList: [],
      },
    }
  },
  computed: {
    saveButtonDisabled() {
      if (JSON.stringify(this.staffInductionEditForm) !== this.staffInductionEditFormExp.staffInductionEditFormJson) {
        return false
      } else {
        return true
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    to.params.fresh = true
    // 路由返回跳转确认
    if (this.staffInductionEditFormExp.staffInductionEditFormJson !== JSON.stringify(this.staffInductionEditForm)) {
      this.$confirm('数据还未保存，确定取消操作吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          next()
        })
        .catch(() => { })
    } else {
      next()
    }
  },
  created() {
    this.$nextTick(() => {
      this.$refs.nativePlaceArea.initSearch()
    })
  },
  mounted() {
    this.staffInductionEditFormExp.zoneCode12From = '000000000000'
    this.staffInductionEditForm.nativePlace = '000000000000'
    // 用人单位ID赋值
    this.staffInductionEditForm.unitId = this.$route.params.unitId
    this.staffInductionEditForm.employerUuid = this.$route.params.employerUuid
    //获取危害因素数据源
    this.getHazardsFactorStandardShow()
    //获取部门车间数据源
    this.getDeptOptions()
    // 获取码表数据源 1003：证件类型；1005：婚姻状况；1007：文化程度
    this.$SimpleCodeTools.getSimpleCodeListByCodeTypeCodes('1003,1005,1007', data => {
      this.staffInductionEditFormExp.idTypeSimpleCodeList = data['1003']
      this.staffInductionEditFormExp.ifMarrySimpleCodeList = data['1007']
      this.staffInductionEditFormExp.cultureSimpleCodeList = data['1005']
      if (this.$zwxBase.verifyIsNotBlank(this.$route.params.rid)) {
        // 查询员工信息
        this.getStaffInfo(this.$route.params.rid)
      } else {
        this.$nextTick(() => {
          this.staffInductionEditForm.idType = '10030001'
          this.staffInductionEditFormExp.staffInductionEditFormJson = JSON.stringify(this.staffInductionEditForm)
        })
      }
      //编辑页面Form表单JSON实例
      this.staffInductionEditFormExp.staffInductionEditFormJson = JSON.stringify(this.staffInductionEditForm)
    })
  },
  methods: {
    /**
     * 获取危害因素数据源
     * */
    getHazardsFactorStandardShow() {
      this.$system.get(
        this.api + '/basic/info/getHazardsStandardExpList-1',
        null,
        true,
        true,
        data => {
          if (data.type == '00') {
            this.staffOccHistoryDialog.hazardShowList = data.dataList
            if (this.$zwxBase.verifyIsNotBlank(this.staffOccHistoryDialog.hazardShowList)) {
              this.staffOccHistoryDialog.hazardShowList.forEach(item => {
                if (this.$zwxBase.verifyIsBlank(item.upcodeNo)) {
                  item.disabled = true
                }
              })
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    getDeptOptions() {
      let data = {
        employerUuid: this.staffInductionEditForm.employerUuid,
        ifTree: true,
      }
      this.$system.get(
        this.api + '/zky/employer/getDepartmentDropdownList-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.staffInductionEditFormExp.departmentInfoList = data.departmentList
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    getStationOptions(deptId, stationId, ifInit) {
      let data = {
        workplaceId: deptId,
      }
      this.$system.get(
        this.api + '/zky/employer/getJobDropdownList-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.staffInductionEditFormExp.deptStationList = data.jobInfoList
            if (this.$zwxBase.verifyIsNotBlank(stationId)) {
              if (ifInit === true) {
                this.$nextTick(() => {
                  this.staffInductionEditForm.stationId = stationId //岗位Id
                  this.staffInductionEditFormExp.staffInductionEditFormJson = JSON.stringify(this.staffInductionEditForm)
                })
              } else {
                this.$nextTick(() => {
                  this.staffInductionEditForm.stationId = stationId //岗位Id
                })
              }
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 部门车间改变回调
     */
    deptChange(value) {
      this.staffInductionEditForm.stationId = ''
      this.getStationOptions(value)
    },
    /**
     * 编辑获取员工信息
     */
    getStaffInfo(rid) {
      let data = {
        rid: rid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getStaffInfo-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.staffInductionEditForm.rid = data.staffInfoExp.rid //员工id
            this.staffInductionEditForm.unitId = data.staffInfoExp.unitId //单位Id
            this.staffInductionEditForm.deptId = data.staffInfoExp.deptId //部门Id
            this.staffInductionEditForm.staffName = data.staffInfoExp.staffName //姓名
            this.staffInductionEditForm.pinyinCode = data.staffInfoExp.pinyinCode //拼音码
            this.staffInductionEditForm.jobNumber = data.staffInfoExp.jobNumber //工号
            this.staffInductionEditForm.birthDate = data.staffInfoExp.birthDate //出生日期
            this.staffInductionEditForm.sex = data.staffInfoExp.sex //性别
            this.staffInductionEditForm.idType = data.staffInfoExp.idType //证件类型
            this.staffInductionEditForm.cardNo = this.$zwxSm.sm4JsDecrypt(data.staffInfoExp.cardNo) //证件号码
            this.staffInductionEditForm.phone = this.$zwxSm.sm4JsDecrypt(data.staffInfoExp.phone) //手机号码
            this.staffInductionEditForm.outWorker = data.staffInfoExp.outWorker //是否外委
            this.staffInductionEditForm.nativePlace = data.staffInfoExp.nativePlace //籍贯
            if (this.$zwxBase.verifyIsNotBlank(this.staffInductionEditForm.nativePlace)) {
              this.$refs.nativePlaceArea.initSearch(this.staffInductionEditForm.nativePlace)
            }
            this.staffInductionEditForm.ifMarry = data.staffInfoExp.ifMarry //婚姻状况
            this.staffInductionEditForm.culture = data.staffInfoExp.culture //文化程度
            this.staffInductionEditForm.homeAddr = data.staffInfoExp.homeAddr //家庭住址
            this.staffInductionEditForm.hobby = data.staffInfoExp.hobby //嗜好
            this.staffInductionEditForm.familyIllness = data.staffInfoExp.familyIllness //家族疾病史
            this.staffInductionEditForm.firstWorkDate = data.staffInfoExp.firstWorkDate || null //初次参加工作时间
            this.staffInductionEditForm.hiredate = data.staffInfoExp.hiredate || null //到本单位工作时间
            this.staffInductionEditForm.prePeDate = data.staffInfoExp.prePeDate || null //岗前体检日期
            this.staffInductionEditForm.preTrainingDate = data.staffInfoExp.preTrainingDate || null //岗前培训日期
            this.staffInductionEditForm.type = data.staffInfoExp.type //人员类型 1：接害人员  2：一般人员
            this.staffInductionEditForm.dutyStatus = data.staffInfoExp.dutyStatus //在职状态;1:在职 2:离职
            // 头像
            this.staffInductionEditForm.personnelPhoto = data.staffInfoExp.personnelPhoto
            if (this.$zwxBase.verifyIsNotBlank(this.staffInductionEditForm.personnelPhoto)) {
              let photoFIle = {
                fileName: '照片',
                filePath: this.staffInductionEditForm.personnelPhoto,
                check: false,
              }
              this.staffInductionEditForm.photoAnnexList.push(photoFIle)
            }
            this.staffInductionEditForm.tdStaffOccHistoryList = data.occupationalHistoryList || []
            this.staffInductionEditForm.staffAnamnesisList = data.anamnesisList || []
            this.getStationOptions(this.staffInductionEditForm.deptId, data.staffInfoExp.stationId, true)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 当证件类型为身份证时校验证件号码
     */
    validatorIdCard(rule, value, callback) {
      let idType = this.staffInductionEditForm.idType
      if (this.$zwxBase.verifyIsBlank(value)) {
        return callback(new Error('请输入证件号码'))
      }
      if (this.$zwxBase.verifyIsNotBlank(idType) && idType === '10030001') {
        this.$validate.idcCode(rule, value, callback)
      }
      return callback()
    },
    /**
     * 手机号校验
     */
    validatorPhone(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value)) {
        this.$validate.mobilePhone(rule, value, callback)
      }
      return callback()
    },
    /**
     * 上岗前职业健康检查日期校验
     */
    validatorPrePeDate(rule, value, callback) {
      if (this.$zwxBase.verifyIsBlank(value) && this.$zwxBase.verifyIsNotBlank(this.staffInductionEditForm.type) && this.staffInductionEditForm.type == true) {
        return callback(new Error('请选择上岗前职业健康检查日期'))
      }
      return callback()
    },
    /**
     * 上岗前职业卫生培训日期校验
     */
    validatorPreTrainingDate(rule, value, callback) {
      if (this.$zwxBase.verifyIsBlank(value) && this.$zwxBase.verifyIsNotBlank(this.staffInductionEditForm.type) && this.staffInductionEditForm.type == true) {
        return callback(new Error('请选择上岗前职业卫生培训日期'))
      }
      return callback()
    },
    /**
     * 校验非空
     * */
    validatorNotEmpty(rule, value, callback) {
      let listName = rule.field.split('.')[0]
      let index = rule.field.split('.')[1]
      let val = rule.field.split('.')[2]
      // 职业史非空校验
      if (this.$zwxBase.verifyIsNotBlank(listName) && listName == 'staffOccHistoryEditList') {
        let obj = this.staffOccHistoryDialog.staffOccHistoryEditForm.staffOccHistoryEditList[index]
        if (this.staffOccHistoryDialog.opType === true && obj.ifVaildator != false && (val !== 'startDate' || val !== 'endDate') && val !== 'exposureHazards' && this.$zwxBase.verifyIsBlank(value)) {
          return callback(new Error('不能为空'))
        }
      }
      // 既往病史非空校验
      if (this.$zwxBase.verifyIsNotBlank(listName) && listName === 'staffAnamnesisList' && (val === 'diseaseName' || val === 'diagnoseDate' || val === 'diagnoseUnit') && this.$zwxBase.verifyIsBlank(value)) {
        return callback(new Error('不能为空'))
      }
      return callback()
    },
    /**
     * 证件类型改变回调
     */
    idTypeChange() {
      if (this.$zwxBase.verifyIsNotBlank(this.staffInductionEditForm.idType) && this.$zwxBase.verifyIsNotBlank(this.staffInductionEditForm.cardNo)) {
        this.validateCardNoRepeat()
      }
    },
    /**
     * 证件号失焦回调
     */
    initBirthDate(event) {
      if (this.$zwxBase.verifyIsNotBlank(this.staffInductionEditForm.idType) && this.$zwxBase.verifyIsNotBlank(this.staffInductionEditForm.cardNo)) {
        this.validateCardNoRepeat(() => {
          let value = event.target.value
          if (this.staffInductionEditForm.idType === '10030001' && value.length === 18) {
            this.$refs['staffInductionEditForm'].validateField(['idType'], valid => {
              if (!valid) {
                let date = value.substring(6, 14)
                let year = date.substring(0, 4)
                let month = date.substring(4, 6)
                let day = date.substring(6, 8)
                let birthDay = new Date(year + '-' + month + '-' + day)
                if (birthDay < new Date()) {
                  this.staffInductionEditForm.birthDate = year + '-' + month + '-' + day
                } else {
                  this.$nextTick(() => {
                    this.$refs.staffInductionEditForm.clearValidate('birthDate')
                  })
                  this.staffInductionEditForm.birthDate = null
                }
                let sex = value.substring(16, 17)
                if (parseInt(sex) % 2 === 0) {
                  this.staffInductionEditForm.sex = '10010002' //女
                } else {
                  this.staffInductionEditForm.sex = '10010001' //男
                }
              }
            })
          }
        })
      }
    },
    /**
     * 校验证件号的重复性
     */
    validateCardNoRepeat(callback) {
      let data = {
        rid: this.staffInductionEditForm.rid,
        unitId: this.staffInductionEditForm.unitId,
        idType: this.staffInductionEditForm.idType,
        cardNo: this.$zwxSm.sm4JsEncrypt(this.staffInductionEditForm.cardNo),
      }
      this.$system.get(
        this.api + '/zky/employer/validateCardNoRepeat-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            let RepeatFlag = data.RepeatFlag
            if (RepeatFlag == 2) {
              this.$notify.error({ title: '错误', message: '此证件号码已存在，请重新输入' })
            } else if (RepeatFlag == 1) {
              this.$system.msgbox('', '提示', '匹配到该员工的历史档案信息，保存后将更新在职状态', '确定', '取消', () => {
                this.staffInductionEditForm.rid = data.staffInfoExp.rid //员工id
                this.staffInductionEditForm.unitId = data.staffInfoExp.unitId //单位Id
                this.staffInductionEditForm.deptId = data.staffInfoExp.deptId //部门Id
                this.staffInductionEditForm.staffName = data.staffInfoExp.staffName //姓名
                this.staffInductionEditForm.pinyinCode = data.staffInfoExp.pinyinCode //拼音码
                this.staffInductionEditForm.jobNumber = data.staffInfoExp.jobNumber //工号
                this.staffInductionEditForm.birthDate = data.staffInfoExp.birthDate //出生日期
                this.staffInductionEditForm.sex = data.staffInfoExp.sex //性别
                this.staffInductionEditForm.idType = data.staffInfoExp.idType //证件类型
                this.staffInductionEditForm.cardNo = this.$zwxSm.sm4JsDecrypt(data.staffInfoExp.cardNo) //证件号码
                this.staffInductionEditForm.phone = this.$zwxSm.sm4JsDecrypt(data.staffInfoExp.phone) //手机号码
                this.staffInductionEditForm.outWorker = data.staffInfoExp.outWorker //是否外委
                this.staffInductionEditForm.nativePlace = data.staffInfoExp.nativePlace //籍贯
                if (this.$zwxBase.verifyIsNotBlank(this.staffInductionEditForm.nativePlace)) {
                  this.$refs.nativePlaceArea.initSearch(this.staffInductionEditForm.nativePlace)
                }
                this.staffInductionEditForm.ifMarry = data.staffInfoExp.ifMarry //婚姻状况
                this.staffInductionEditForm.culture = data.staffInfoExp.culture //文化程度
                this.staffInductionEditForm.homeAddr = data.staffInfoExp.homeAddr //家庭住址
                this.staffInductionEditForm.hobby = data.staffInfoExp.hobby //嗜好
                this.staffInductionEditForm.familyIllness = data.staffInfoExp.familyIllness //家族疾病史
                this.staffInductionEditForm.firstWorkDate = data.staffInfoExp.firstWorkDate || null //初次参加工作时间
                this.staffInductionEditForm.hiredate = data.staffInfoExp.hiredate || null //到本单位工作时间
                this.staffInductionEditForm.prePeDate = data.staffInfoExp.prePeDate || null //岗前体检日期
                this.staffInductionEditForm.preTrainingDate = data.staffInfoExp.preTrainingDate || null //岗前培训日期
                this.staffInductionEditForm.type = data.staffInfoExp.type //人员类型 1：接害人员  2：一般人员
                this.staffInductionEditForm.dutyStatus = 1 //在职状态;1:在职 2:离职
                // 头像
                this.staffInductionEditForm.personnelPhoto = data.staffInfoExp.personnelPhoto
                if (this.$zwxBase.verifyIsNotBlank(this.staffInductionEditForm.personnelPhoto)) {
                  let photoFIle = {
                    fileName: '照片',
                    filePath: this.staffInductionEditForm.personnelPhoto,
                    check: false,
                  }
                  this.staffInductionEditForm.photoAnnexList.push(photoFIle)
                }
                this.staffInductionEditForm.tdStaffOccHistoryList = data.occupationalHistoryList || []
                this.staffInductionEditForm.staffAnamnesisList = data.anamnesisList || []
                this.getStationOptions(this.staffInductionEditForm.deptId, data.staffInfoExp.stationId)
              })
            } else {
              // 校验成功后回调
              callback()
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        // () => {
        //   this.$system.notify('错误', '网络连接失败', 'error')
        // }
      )
    },
    /**
     * 日期格式化
     */
    dateFormatter(row, column, cellValue) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return this.$system.formatDate(cellValue, 'YYYY-MM-DD')
      }
    },
    /**
     * 籍贯改变回调
     */
    nativePlaceChange() {
      this.staffInductionEditForm.nativePlace = this.$refs.nativePlaceArea.getChooseArea().zoneCode12
    },
    /**
     * 岗位改变回调
     */
    workTypeChange(value) {
      let arr = this.staffInductionEditFormExp.deptStationList.filter(item => item.rid === value)
      if (this.$zwxBase.verifyIsNotBlank(arr) && arr[0].exposureHazards == true) {
        this.staffInductionEditForm.type = 1
      }
      if (this.$zwxBase.verifyIsNotBlank(arr) && arr[0].exposureHazards == false) {
        this.staffInductionEditForm.type = 2
      }
    },
    clickPhotoPositive(showList) {
      this.staffInductionEditForm.photoAnnexList = []
      showList.forEach(item => {
        let flag = this.staffInductionEditForm.photoAnnexList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.staffInductionEditForm.photoAnnexList.push({
          fileName: item,
          check: false,
        })
      })
    },
    delPhotoPositive() {
      let fileName = this.staffInductionEditForm.photoAnnexList[0].fileName
      let filePath = this.staffInductionEditForm.photoAnnexList[0].filePath
      this.staffInductionEditForm.photoAnnexList.splice(0, 1)
      this.staffInductionEditForm.personnelPhoto = ''
      if (this.$zwxBase.verifyIsBlank(filePath)) {
        this.$refs.photoPositive.deleteIndex(0)
      }
      let fileList = this.$refs.photoPositive.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.photoPositive.deleteIndex(j)
            fileList = this.$refs.photoPositive.showFileList || []
            continue
          }
        }
      }
    },
    photoMouseenter(index) {
      this.staffInductionEditForm.photoAnnexList[index].check = true
    },
    photoMouseleave(index) {
      this.staffInductionEditForm.photoAnnexList[index].check = false
    },
    clickPhotoPositiveSuccess(fileList) {
      if (this.$zwxBase.verifyIsNotBlank(this.staffInductionEditForm.photoAnnexList) && this.$zwxBase.verifyIsNotBlank(fileList)) {
        for (let i in this.staffInductionEditForm.photoAnnexList) {
          for (let j in fileList) {
            if (this.staffInductionEditForm.photoAnnexList[i].fileName === fileList[j].fileName) {
              this.staffInductionEditForm.photoAnnexList[i].filePath = fileList[j].filePath
            }
          }
        }
        this.staffInductionEditForm.personnelPhoto = this.staffInductionEditForm.photoAnnexList[0].filePath
      }
      this.saveStaffInfo()
    },
    /**
     * 获取文件上传uuid
     */
    generateFilesUid() {
      let data = {
        employerUuid: this.staffInductionEditForm.employerUuid,
        folder: this.staffInductionEditFormExp.folder,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/systematic/tools/getAnnexUploadUid-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$refs.photoPositive.submit(data.annexUploadUid)
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 文件预览
     */
    openFilePreview(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    /**
     * 编辑职业史
     */
    editStaffOccHistory(row, index) {
      this.$refs.staffOccHistoryDialogRef.show(true)
      this.staffOccHistoryDialog.title = '编辑职业史'
      this.staffOccHistoryDialog.type = 'edit'
      this.staffOccHistoryDialog.rowIndex = index
      this.staffOccHistoryDialog.staffOccHistoryEditForm.uuid = this.$system.uuid()
      this.staffOccHistoryDialog.staffOccHistoryEditForm.employerName = row.employerName
      this.staffOccHistoryDialog.staffOccHistoryEditForm.department = row.department
      this.staffOccHistoryDialog.staffOccHistoryEditForm.workType = row.workType
      this.staffOccHistoryDialog.staffOccHistoryEditForm.startDate = row.startDate
      this.staffOccHistoryDialog.staffOccHistoryEditForm.endDate = row.endDate
      this.staffOccHistoryDialog.staffOccHistoryEditForm.exposureHazards = row.exposureHazards
      this.staffOccHistoryDialog.staffOccHistoryEditForm.occupationalHistoryHazardExpList = row.occupationalHistoryHazardExpList
      let hazardsCodeList = []
      if (this.$zwxBase.verifyIsNotBlank(row.occupationalHistoryHazardExpList)) {
        row.occupationalHistoryHazardExpList.forEach(item => {
          item.codeNo = item.hazardsCode
          hazardsCodeList.push(item.hazardsCode)
        })
      }
      this.$nextTick(() => {
        this.$refs['hazardFactorMultipleEditRef' + this.staffOccHistoryDialog.staffOccHistoryEditForm.uuid].setEchoCheckDataCodeList(hazardsCodeList)
      })
    },
    /**
     * 删除职业史
     */
    deleteStaffOccHistory(index) {
      this.staffInductionEditForm.tdStaffOccHistoryList.splice(index, 1)
    },
    /**
     * 添加职业史
     */
    addStaffOccHistory() {
      this.$refs.staffOccHistoryDialogRef.show(true)
      this.staffOccHistoryDialog.title = '添加职业史'
      this.staffOccHistoryDialog.type = 'add'
      this.staffOccHistoryDialog.staffOccHistoryEditForm.uuid = this.$system.uuid()
    },
    /**
     * 职业史危害因素改变回调
     * */
    hazardsChange(data) {
      this.staffOccHistoryDialog.staffOccHistoryEditForm.exposureHazards = data.hazardNames
      let hazardsCodeList = []
      let list = []
      if (this.$zwxBase.verifyIsNotBlank(data.checkDataList)) {
        list = data.checkDataList
        list.forEach(item => {
          item.hazardsCode = item.codeNo
          item.hazardsName = item.hazardName
          hazardsCodeList.push(item.codeNo)
        })
        this.staffOccHistoryDialog.staffOccHistoryEditForm.hazardCode = hazardsCodeList.join('、')
      }
      this.staffOccHistoryDialog.staffOccHistoryEditForm.occupationalHistoryHazardExpList = list
      this.staffOccHistoryDialog.staffOccHistoryEditForm.hazardsCodeList = hazardsCodeList
    },
    /**
     * 提交职业史弹框
     */
    confirmStaffOccHistoryDialog() {
      this.$refs.staffOccHistoryEditFormRef.validate(valid => {
        if (valid) {
          if (this.staffOccHistoryDialog.type === 'add') {
            let arr = []
            arr.push(JSON.parse(JSON.stringify(this.staffOccHistoryDialog.staffOccHistoryEditForm)))
            this.staffInductionEditForm.tdStaffOccHistoryList = arr.concat(this.staffInductionEditForm.tdStaffOccHistoryList)
          } else if (this.staffOccHistoryDialog.type === 'edit') {
            let staffOccHistoryEditForm = JSON.parse(JSON.stringify(this.staffOccHistoryDialog.staffOccHistoryEditForm))
            let arr = JSON.parse(JSON.stringify(this.staffInductionEditForm.tdStaffOccHistoryList))
            let staffOccHistory = arr[this.staffOccHistoryDialog.rowIndex]
            staffOccHistory.employerName = staffOccHistoryEditForm.employerName
            staffOccHistory.department = staffOccHistoryEditForm.department
            staffOccHistory.workType = staffOccHistoryEditForm.workType
            staffOccHistory.startDate = staffOccHistoryEditForm.startDate
            staffOccHistory.endDate = staffOccHistoryEditForm.endDate
            staffOccHistory.exposureHazards = staffOccHistoryEditForm.exposureHazards
            staffOccHistory.occupationalHistoryHazardExpList = staffOccHistoryEditForm.occupationalHistoryHazardExpList
            this.staffInductionEditForm.tdStaffOccHistoryList = arr
          }
          this.$refs.staffOccHistoryDialogRef.show(false)
        }
      })
    },
    /**
     * 取消职业史弹框
     */
    cancelStaffOccHistoryDialog() {
      this.$refs.staffOccHistoryDialogRef.show(false)
    },
    /**
     *关闭职业史弹框回调
     */
    closeStaffOccHistoryDialog() {
      this.$nextTick(() => {
        this.staffOccHistoryDialog.type = ''
        this.staffOccHistoryDialog.rowIndex = null
        this.staffOccHistoryDialog.staffOccHistoryEditForm.employerName = ''
        this.staffOccHistoryDialog.staffOccHistoryEditForm.department = ''
        this.staffOccHistoryDialog.staffOccHistoryEditForm.workType = ''
        this.staffOccHistoryDialog.staffOccHistoryEditForm.startDate = ''
        this.staffOccHistoryDialog.staffOccHistoryEditForm.endDate = ''
      })
    },
    /**
     * 添加既往病史
     * */
    addStaffAnamnesis() {
      let data = {
        rid: '',
        staffId: this.staffInductionEditForm.rid,
        diseaseName: '',
        diagnoseDate: '',
        diagnoseUnit: '',
        treatResult: '',
        remark: '',
      }
      this.staffInductionEditForm.staffAnamnesisList.push(data)
    },
    /**
     * 删除职业史
     * */
    delStaffAnamnesis(index) {
      this.staffInductionEditForm.staffAnamnesisList.splice(index, 1)
    },
    /**
     * 保存员工信息
     */
    submitStaffInfo() {
      this.$refs.staffInductionEditForm.validate(valid => {
        if (valid) {
          this.generateFilesUid()
        }
      })
    },
    /**
     * 保存员工信息
     */
    saveStaffInfo() {
      let data = {
        ...this.staffInductionEditForm,
      }
      data.staffAnamnesisExpList = this.staffInductionEditForm.staffAnamnesisList
      data.occupationalHistoryExpList = this.staffInductionEditForm.tdStaffOccHistoryList
      delete data.tdStaffOccHistoryList
      delete data.staffAnamnesisList
      data.deptName = this.$refs.deptCascaderRef.inputValue
      data.stationName = this.$refs.stationSelectRef.selectedLabel
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/zky/employer/addOrModifyStaffInfo-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.staffInductionEditFormExp.staffInductionEditFormJson = JSON.stringify(this.staffInductionEditForm)
            this.$system.notify('成功', data.mess, 'success')
            this.$router.push({
              name: 'StaffInductionMainPage',
              params: { fresh: true, employerUuid: this.staffInductionEditForm.employerUuid },
            })
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
.staff-induction-edit-page {
  /deep/ .cascader-single-common .zwx-input {
    width: 200px !important;
  }

  /deep/ .dept-cascader .zwx-input {
    width: 200px !important;
  }

  /deep/ .zwx-select-station .el-input__inner {
    width: 200px !important;
  }

  /deep/ .zwx-select-area-native-place .zwx-input {
    width: 200px !important;
  }

  /deep/ .staff-occ-history-dialog .el-dialog {
    width: 580px !important;
    min-width: 580px !important;
  }

  /deep/ .el-upload-list__item {
    width: unset;
  }
}
</style>
<style lang="less">
.hazard-factor-multiple-popover .el-tree-node__content > label.is-disabled {
  display: none;
  margin-right: unset;
}
</style>
